<template>
  <div id="app">
    <header>
      <a href="/" class="logo-link">
        <img alt="Company Logo" src="./assets/logo.png" class="logo" />
      </a>
      <nav>
        <button class="menu-toggle" @mouseover="openMenu" @mouseleave="closeMenu">
          ☰
        </button>
        <ul :class="{ 'active': isMenuOpen }" @mouseover="openMenu" @mouseleave="closeMenu">
          <li v-for="(item, index) in menuItems" :key="index">
            <router-link :to="item.to" @click="closeMenu">{{ item.label }}</router-link>
          </li>
          <template v-if="!isAuthenticated">
            <li><router-link to="/dang-nhap" @click="closeMenu">Đăng Nhập</router-link></li>
          </template>
          <template v-else>
            <li><span>{{ email }}</span></li>
            <li><router-link to="/" @click="closeMenu">HKOIL</router-link></li>
            <li><router-link to="/logout" @click="closeMenu">User Profile</router-link></li>
          </template>
        </ul>
      </nav>
    </header>
    <main>
      <div class="content-container">
        <router-view :systems="systems" />
        <button v-if="pwaStore.deferredPrompt" class="install-button" @click="pwaStore.promptInstall">
          Cài Đặt Ứng Dụng
        </button>
      </div>
    </main>
    <footer>
      <p>&copy; 2024 HKOIL. Tất cả các quyền được bảo lưu.</p>
    </footer>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { usePWAStore } from './stores/usePWAStore'; // Import store
import { useAuthStore } from './stores/authStore'; // Import auth store

export default defineComponent({
  name: 'App',
  setup() {
    const pwaStore = usePWAStore(); // Khởi tạo store PWA
    const authStore = useAuthStore(); // Khởi tạo store Auth

    const isAuthenticated = computed(() => authStore.isAuthenticated); // Kiểm tra trạng thái đăng nhập
    const email = computed(() => authStore.email || ''); // Lấy email từ auth store

    return { pwaStore, isAuthenticated, email };
  },
  data() {
    return {
      isMenuOpen: false,
      menuItems: [
        { label: 'Trang Chính', to: '/' },
        { label: 'Giới thiệu', to: '/hkoil' },
        { label: 'Về Chúng Tôi', to: '/about' },
        { label: 'Chấm công', to: '/att-user' },
        { label: 'Thông báo', to: '/att-pe' },
        { label: 'Roadmap', to: '/timeline' },
      ],
      systems: [{ id: 1, name: "1. Đề nghị thanh toán", link: "https://duyetchi.hkoil.org", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              { id: 2, name: "2. Chấm Công", link: "/att-user", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              { id: 3, name: "3. Đánh giá phát triển nhân sự", link: "https://veas.hkoil.org", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              { id: 4, name: "4. Notifications", link: "/att-pe", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              { id: 5, name: "5. Tính thuế TNCN", link: "https://www.topcv.vn/tinh-thue-thu-nhap-ca-nhan", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              { id: 6, name: "6. Xin nghỉ phép", link: "#", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              { id: 7, name: "7. Tính lãi suất vay", link: "/loan", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              { id: 8, name: "8. Tính lãi suất tiết kiệm", link: "/saving", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              { id: 9, name: "9. Công cụ tiện ích", link: "https://techcombank.com/cong-cu-tien-ich", image: "https://cdn.hkoil.org/img/512hklogo.png" },
              // ... Thêm nhiều hệ thống khác tại đây
      ]
    };
  },
  methods: {
    openMenu() {
      this.isMenuOpen = true;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
  mounted() {
    document.title = 'HKOIL I-OFFICE';
  },
});
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: linear-gradient(90deg, #ffcf3ec2, #ff805cbd);
  animation: gradient-shift 6s infinite alternate;
  color: #fff;
  background-size: 400% 400%;
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.logo {
  width: 100px;
  height: auto;
}

nav ul {
  display: flex;
  gap: 15px;
  list-style: none;
  padding: 0;
  margin: 0;
}

nav li {
  transition: transform 0.2s ease, color 0.3s ease;
}

nav li:hover {
  transform: translateY(-5px);
  color: #007acc;
}

.menu-toggle {
  display: none;
}
.menu-toggle:hover {
  transform: scale(1.2); /* Phóng to nhẹ khi hover */
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    font-size: 1.5rem;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
  }

  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  nav ul.active {
    display: flex;
  }

  nav li {
    text-align: center;
  }
}

a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s ease;
  border-radius: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a:hover {
  background: rgba(255, 255, 255, 0.2);
}

footer {
  text-align: center;
  background: #f3f3f3;
  padding: 15px;
  font-size: 0.9rem;
  border-top: 1px solid #eaeaea;
  margin-top: auto;
}

main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.content-container {
  width: 95%;
  max-width: 1200px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #ffcf3ec2;
    position: absolute;
    top: 60px;
    left: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

  nav ul.active {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }

  nav li {
    margin: 10px 0;
  }

  a {
    padding: 9px;
    font-size: 18px;
  }
}

.install-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #FF6347;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 99, 71, 0.4);
}

.install-button:hover {
  transform: translateY(-5px) scale(1.05);
  background-color: #FF4500;
  box-shadow: 0 6px 25px rgba(255, 69, 0, 0.6);
}

.install-button:active {
  transform: scale(0.95);
}
</style>

